'use strict';
// Initialize a new Tooltip with extended functionality for all current elements with the class of has-tip
$('.has-tip').each(function (i, e){
  new Foundation.Tooltip($(e), {
    allowHtml: true
  }); // close tooltip
}); // close each
$(document).foundation();

$.blockUI.defaults = {
  // message displayed when blocking (use null for no message)
  message: '<div class="loader-wrap">' +
               '<div class="loader">' +
                '<svg class="round" viewBox="25 25 50 50">' +
                  '<circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" />' +
                '</svg>' +
              '</div>' +
              '</div>',
  baseZ: 9800
  // styles for the message when blocking; if you wish to disable
  // these and use an external stylesheet then do this in your code:
  // $.blockUI.defaults.css = {};
  // css: {
  //     padding:        0,
  //     margin:         0,
  //     width:          '100%',
  //     top:            '100%',
  //     left:           '0',
  //     textAlign:      'center',
  //     color:          '#000',
  //     border:         '3px solid #aaa',
  //     backgroundColor: 'rgba(255, 255, 255, 0.8)',
  //    cursor:         'wait',
  // }
};
$.blockUI.defaults.css = {};

function blockElement(element){
  if (element){
    const height = element.outerHeight();
    $.blockUI.defaults.css = { 'min-height': height + 'px', 'z-index': 'inherit' };

    // Parameter data-loading_content_container is used to visually include the loading circle in the block the content is loading to
    if (element.attr('data-loading_content_container')) {
      let loadingContentContainer = element.attr('data-loading_content_container');
      $('#'+loadingContentContainer).addClass('loading-circle-padding');
    }

    element.block();
    $('.loader-wrap').css({ height: height + 'px' });
  }
}

function unblockElement(element){
  if (element){
    if (element.attr('data-loading_content_container')) {
      let loadingContentContainer = element.attr('data-loading_content_container');
      $('#'+loadingContentContainer).removeClass('loading-circle-padding');
    }

    element.unblock();
  }
}

function loadAjaxBoxContent(){
  $('.js-ajaxLoadBox').each(function (i, obj){
    const element = $(this);
    fillInvisibleContent(element, element.data('callback'));
  });
  $('.tabs').foundation();
}

function fillInvisibleContent(element, callback){
  const url = element.attr('data-url');
  const replace = element.attr('data-replace');
  const msg = element.parent('.js-messages');
  $.ajax({
    url: url,
    method: 'GET',
    beforeSend: function (){
      if (!msg.length) blockElement(element);
    },
    success: function (response){
      if (replace){
        element.replaceWith(response.responseView);
      } else {
        element.html(response.responseView);
      }

      if (!msg.length) unblockElement(element);
      $('.tabs').foundation();
      $('.dropdown-pane').foundation();
      $(document).foundation();

      if (typeof callback !== 'undefined'){
        let func = window[callback];
        func(response);
      }
      element.find('.js-ajaxLoadBox').each(function (i, obj){
        fillInvisibleContent($(this));
      });
      element.find('.js-injected-form-box').each(function (i, obj){
        initInjectedForm($(obj));
      });

      if($('.js_generic_list_of_boxes').length) {
        genericMutationObserver();
      }
    },
    complete: function (){
      $('.accordion').foundation();
      $('.js-dropdown-active').foundation();
    },
    error: function (error){
      // console.log(error);
      element.html();
    }
  });
}

function fillContent(element, whatToBlock){
  const url = element.data('url');
  whatToBlock = (typeof whatToBlock !== 'undefined') ? whatToBlock : element;
  $.ajax({
    url: url,
    method: 'GET',
    beforeSend: function (){
      blockElement(whatToBlock);
    },
    success: function (response){
      element.html(response.responseView);
      $('.tabs').foundation();
    },
    complete: function (){
      unblockElement(whatToBlock);
    }
  });
}

function addPrototypeElement(container, prototype, index){
  index = parseInt(index);

  // Replace '__name__' in the prototype's HTML to
  // instead be a number based on how many items we have but take last number
  const newForm = prototype.replace(/__name__/g, index);

  // increase the index with one for the next item
  container.attr('data-index', index + 1);

  // Display the form in the page
  container.append(newForm);
}

function scrollToElement(elem, offsetTop = 200){
  $('html, body').animate({
    scrollTop: elem.offset().top - offsetTop
  }, 1000);
}

function execCallbackMethod(callback, result){
  if (undefined !== callback){
    const func = window[callback];
    if (typeof func === 'function'){
      func(result);
    }
  }
}

function displayException(errorMessage){
  const errorMessageBase = "<section class='callout warning'>An error has occurred , Please contact our office!</section>";

  if (errorMessage){
    errorMessage = errorMessageBase + '<section class="callout alert">' + errorMessage + '</section>';
  } else {
    errorMessage = errorMessageBase;
  }

  openFoundationMessage(errorMessage);
}
function openNotClosableFoundationMessage(message,modalType = 'modal--confirm'){
  if ($('#openFoundationMessage')){
    $('#openFoundationMessage').remove();
  }

  const modal = $(`<div class="modal-without-actions reveal v2 modal ${ modalType }" id="openFoundationMessage"
                        data-reveal data-multiple-opened role="dialog" data-close-on-click="false" data-close-on-esc="false">
                        <div id="ajaxModalContentWraper">${ message }</div>
                    </div>`);
  $('body').append(modal);
  $('#openFoundationMessage').foundation();
  $('#openFoundationMessage').foundation('open');
}
function openFoundationMessage(message, modalType = 'modal--confirm', size = 'small'){
  if ($('#openFoundationMessage')){
    $('#openFoundationMessage').remove();
  }

  const modal = $(`<div class="${size} reveal v2 modal ${ modalType }" id="openFoundationMessage"
                        data-reveal data-multiple-opened role="dialog">
                            <button class="close-button modal__close-btn" data-close aria-label="Close reveal" type="button">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        <div id="ajaxModalContentWraper">${ message }</div>
                    </div>`);
  $('body').append(modal);
  $('#openFoundationMessage').foundation();
  $('#openFoundationMessage').foundation('open');
}

function refreshListWithPagination(){
  const noResultsOnPage = $('#js-box-0').find('.profile-data').length == 0 && $('#js-box-0').find('.ajax-data').length == 0;

  if (noResultsOnPage && $('.js-pagination-previous').length){
    $('.js-pagination-previous').click();
  } else if ($('.js-pagination-next').length){
    $('.sp-pagination__page--current').click();
  }
}

function reloadPageWithPagination(){
  if ($('.sp-pagination__page--current').length){
    $('.sp-pagination__page--current').click();
  } else if ($('.sp-pagination__page').length){
    $('.sp-pagination__page')[0].click();
  } else {
    location.reload();
  }
}

function commonRefreshListWithPagination(){
  if ($('.js-list .js-item').length === 0){
    if ($('.js-pagination-previous').length){
      $('.js-pagination-previous').click();
    } else {
      loadAjaxBoxContent();
    }
  }
}

function redirectToPreviousPageIfNoResults(){
  if ($('.table-sp__body > tr').length === 0){
    if ($('.js-pagination-previous').length){
      $('.js-pagination-previous').click();
    } else {
      loadAjaxBoxContent();
    }
  }
}

function scrollToThePageTop(){ // TODO: check if used anywhere else form landing page
  $('html, body').animate({ scrollTop: '0px' }, 0);
}

// this callback function registrationFormNotValid() takes care of page central content padding when registration form is not valid after submit.
function registrationFormNotValid() {
  if ($('#registration_container').find('.input-text.error').length > 0) {
    $('.about-block').addClass('about-block__with-validation-messages');
  } else {
    $('.about-block').removeClass('about-block__with-validation-messages');
  }
}

// add css to pseudo elements, :before or :after
(function ($){
  jQuery.fn.extend({
    getPseudo: function (pseudo, prop){
      const props = window.getComputedStyle(
        $(this).get(0), pseudo
      ).getPropertyValue(prop);
      return String(props);
    },
    setPseudo: function (_pseudo, _prop, newprop){
      const elem = $(this);
      const s = $('style');
      const p = elem.getPseudo(_pseudo, _prop);
      const r = p !== '' ? new RegExp(p) : false;
      const selector = $.map(elem, function (val, key){
        return [val.tagName,
          val.id
            ? '#' + val.id : null,
          val.className ? '.' + val.className : null];
      });
      const _setProp = '\n' + selector.join('')
        .toLowerCase()
        .concat(_pseudo)
        .concat('{')
        .concat(_prop + ':')
        .concat(newprop + '};');
      return ((r ? r.test($(s).text()) : r) ? $(s)
        .text(function (index, prop){
          return prop.replace(r, newprop);
        }) : $(s).append(_setProp)
      );
    }
  });
})(jQuery);

function confirmation_dialog(callback, messageTitle, message, confirmButton, cancelButton, confirmationIcon) {

  /* available properties
  * data-callbacks: list of js methods to call after ajax (comma separated list)
  * data-url | REQUIRED | : ajax url to be called
  * data-container : dom element id where to display result
  * data-confirmation: display confirmation message (1-display)
  * data-confirmbutton: default value is OK, not mandatory
  * data-cancelbutton: default value is Cancel, not mandatory
  * data-confirmationtitle: default value is 'Confirm', not mandatory
  * data-confirmationmessage: default value is 'Are you sure you want to delete this item?', not mandatory
  * data-confirmationicon: default value is empty, not mandatory, to use data-confirmationicon use name of icon without icon- prefix,
  * example: icon-svg--info-circle -> svg--info-circle
  */

  // TODO: implement a better way to prevent popup remaining
  // in the DOM after click on outside popup if possible
  if ($('#foundation_confirmation')){
    $('#foundation_confirmation').remove();
    $(".reveal-overlay").remove();
  }

  // appending new reveal modal to the page
  if (cancelButton === undefined){
    cancelButton = 'Cancel';
  }
  let modal;
  // This template is default confirmation template,
  // which is redendered if there is no data-confirmationicon set
  let defaultConfirmationTemplate = `<div class="v2 reveal new-modal-confirm" id="foundation_confirmation">
      <button class="js-close-confirmation-button close-button new-modal-confirm__close" aria-label="Close reveal" type="button" data-close>
        <svg class="icon icon-svg--close new-modal-confirm__close-icon">
          <use xlink:href="#svg--close"></use>
        </svg>
      </button>
  <div class="new-modal-confirm__title">${ messageTitle }</div>
  <div class="new-modal-confirm__message">${ message }</div>
  <div class="new-modal-confirm__actions">
      <button class="v2 btn btn--outline btn--md margin-right js-close-confirmation-button" data-close>
        ${ cancelButton }
      </button>
    <button class="v2 btn btn--primary btn--md js-confirm-btn" id="yes_confirmation">${ confirmButton }</button>
  </div>
</div>`;


  // This confirmation template is rendered if there is data-confirmationicon is set
  let iconConfirmationTemplate = `<div class="v2 reveal new-modal-confirm" id="foundation_confirmation">
  <div class="new-modal-confirm-header">
    <svg class="icon icon-${ confirmationIcon } new-modal-confirm-header__default-icon">
      <use xlink:href="#${ confirmationIcon }"></use>
    </svg>
    <button class="js-close-confirmation-button new-modal-confirm-header__close" aria-label="Close reveal" type="button" data-close>
          <svg class="icon icon-svg--close new-modal-confirm-header__close-icon">
            <use xlink:href="#svg--close"></use>
          </svg>
    </button>
  </div>
  <div class="new-modal-confirm__title text-align-center">${ messageTitle }</div>
  <div class="new-modal-confirm__message text-align-center">${ message }</div>
  <div class="new-modal-confirm__actions new-modal-confirm__actions--center">
      <button class="v2 btn btn--outline btn--md margin-right js-close-confirmation-button" data-close>
        ${ cancelButton }
      </button>
    <button class="v2 btn btn--primary btn--md js-confirm-btn" id="yes_confirmation">${ confirmButton }</button>
  </div>
</div>`;

  if(!confirmationIcon) {
    modal = defaultConfirmationTemplate;
  } else {
    modal = iconConfirmationTemplate;
  }

  $('body').append(modal);
  const confirmationDialog = new Foundation.Reveal($('#foundation_confirmation'));
  confirmationDialog.open();
  const modal_object = $('#foundation_confirmation');

  $('#yes_confirmation').on('click', function (e){
    // close and REMOVE FROM DOM to avoid multiple binding
    confirmationDialog.close();
    confirmationDialog.destroy();
    modal_object.remove();
    // calling the function to process
    callback.call();
  });
  $('.js-close-confirmation-button').on('click', function (e){
    // close and REMOVE FROM DOM to avoid multiple binding
    confirmationDialog.close();
    confirmationDialog.destroy();
    modal_object.remove();
    // calling the function to process
  });
}
$(document).ready(function (){

  $(document).on('keydown.autocomplete', '.js-autocomplete', function (e){
    const element = $(this);
    const url = element.data('url');
    const minlen = element.data('minlen');
    element.autocomplete({
      minLength: minlen,
      source: function (request, response){
        const term = request.term.trim();
        $.ajax({
          method: 'POST',
          url: url + '/' + term,
          success: function (data){
            const items = [];
            $.each(data, function (i, item){
              if (item.name.toLowerCase().indexOf(term.toLowerCase()) != -1){
                items.push({ label: item.name, value: item.id });
              }
            });
            response(items);
          }
        });
      },
      focus: function (event, ui){
        event.preventDefault();

        $(this).val(ui.item.label);
      },
      select: function (event, ui){
        event.preventDefault();

        element.val(ui.item.label);
      }
    });
  });
  $(document).on('click', '.js-close-button', function (e){
    // after registration when recommended people popup is closed
    // reload and show side bar box with recommended people
    if ($('.js-btn-ajax-follow').length && $('.recommended_people_wrap').length){
      const sideBarReccommendedPeople = $('.recommended_people_wrap'); // it is hidden for new users because recommended people popup shows up
      fillInvisibleContent(sideBarReccommendedPeople);
      sideBarReccommendedPeople.show();
    }

    $('#ajaxModalWithJs').foundation('close');
    $('#ajaxModalWithJs').foundation('_destroy');
    $('#ajaxModalWithJs').remove();
  });
  $(document).on('click', '.js-close-button-message', function (e){
    $('#openFoundationMessage').foundation('close');
    $('#openFoundationMessage').foundation('_destroy');
    $('#openFoundationMessage').remove();
  });

  $('.js-toggle-submenu').click(function (e){
    e.preventDefault();
    e.stopPropagation();
    $(this).next('li.toggleable-submenu').toggle();
  });

  $('#js-hamburger-menu').click(function (e){
    $('body').toggleClass('hamburger-show hamburger-hide');
  });
  // Table checkbox
  $('.table-sp__checkbox').click(function (){
    const checkbox = $(this).find('input');
    if (checkbox.is(':checked')){
      $(this).addClass('table-sp__checkbox--checked');
      $(this).closest('tr').addClass('selected');
    } else {
      $(this).removeClass('table-sp__checkbox--checked');
      $(this).closest('tr').removeClass('selected');
    }
  });

  (function dashboardSubmenu(){
    const profileNavItem = $('.profile-nav__item');
    const profileNavItemParent = $('.profile-nav__item--active').parents('.profile-nav__submenu');
    if (profileNavItem.hasClass('profile-nav__item--active')){
      profileNavItemParent.css('display', 'block');
    }
  })();

  $('.js-secondary-button-submit').click(function () {
    submitSearch($(this));
  })

  $('.js-header-search-input').keydown(function (e) {
    const code = e.keyCode ? e.keyCode : e.which;
    if (code === 13){ // Enter
      e.preventDefault();
      submitSearch($(this));
    }
  })

  // submit search
  function submitSearch (searchElement) {
    const searchForm = searchElement.parents('form:first');
    const searchInput = searchForm.find('input');
    const minCharacters = 3; // NOTE: important parameter!

    if (searchInput.val().trim().length >= minCharacters){
      $('.header-search').block();
      $(searchForm).trigger('submit');
    }
  }

  $('.header-search').click(function (e){
    e.stopPropagation();
  });

  // adds border on conference view untill we find better css solution
  // TODO: remove planned conference border
  $('.profile-data--planned:last').addClass('profile-data--last-of-planned');
  loadAjaxBoxContent();

  $('#to-top').on('click', function () {
    const speed = 250;

    $('body, html').stop().animate({
      scrollTop: 0
    }, speed);
  });

  $('#to-bottom').on('click', function () {
    const speed = 250;

    $('body, html').stop().animate({
      scrollTop: $(document).height()
    }, speed);
  });

  $(document).on('click', '.js-toggle-authors', function (){
    $(this).parent('.js-authors-container').find('.js-show-hide-div').each(function (i, object){
      $(object).removeClass('js-show-hide-div');
    });
    $('.js-toggle-authors').addClass('hide');
  });

  $(document).on('click', '.js-author-show', function (){
    $(this).siblings('.js-sh-div').show();
    $(this).siblings('.js-author-hide').show();
    $(this).siblings('.js-author-show-separator').hide();
    $(this).hide();
  });

  $(document).on('click', '.js-author-hide', function (){
    $(this).siblings('.js-sh-div').hide();
    $(this).siblings('.js-author-show').show();
    $(this).siblings('.js-author-show-separator').show();
    $(this).hide();
  });
  $(document).on('click', '.js-ajax-remove-object', function (e){
    e.preventDefault();
    const objectName = $(this).data('class');
    const id = $(this).data('id');
    const hash = $(this).data('hash');
    if ($(this).data('parent')){
      var parent = '/' + $(this).data('parent');
    } else {
      var parent = '';
    }

    const containerId = $(this).data('container_id');
    const confirmationMessage = $(this).data('confirmationmessage') ? $(this).data('confirmationmessage') : null;
    const url = $(this).data('url') ? $(this).data('url') : ('/base/removeobject/' + objectName + '/' + id + '/' + hash + parent);
    const containerElem = $('#' + containerId);
    const containerElemWrap = $('#' + containerId + '-wrapper');
    const callback = $(this).data('callback');
    let flashMessage = null;
    const ajaxFunction = function (){
      $.ajax({
        url: url,
        method: 'POST',
        beforeSend: function (){
          blockElement($('#' + containerId));
        },
        success: function (result){
          if (result.goToUrl){
            window.location.href = result.goToUrl;
          }
          if (result.success){
            if (containerElem.length){
              containerElem.fadeOut(300, function (){
                containerElem.remove();
              });
              // containerElem.remove();
              containerElemWrap.remove();
            }

            execCallbackMethod(result);
          }
          // if (result.success == 'false') {
          //     FlashMessage.showFlashMessages(data.message, data.messageType);
          // }
          if (result.hasOwnProperty('flashMessage')){
            flashMessage = result.flashMessage;
          }

          reinitTooltip();
        },
        fail: function (xhr, textStatus, errorThrown){
          openFoundationMessage('An error has occurred , Please contact our office!');
        },
        complete: function (){
          unblockElement($('#' + containerId));
          if (flashMessage){
            FlashMessage.showFlashMessages(flashMessage.message, flashMessage.messageType);
          } else {
            FlashMessage.showFlashMessagesCallback();
          }
        }
      });
    };
    if (confirmationMessage){
      $('<p></p>').confirm(
        confirmationMessage,
        function (event){
          if (event.response){
            ajaxFunction();
          }
        },
        false,
        'Delete',
        'Cancel'
      );
    } else {
      ajaxFunction();
    }
  });
  $(document).on('click', '.js-remove-object', function (event){
    event.preventDefault();

    const objectName = $(this).data('class');
    const id = $(this).data('id');
    const hash = $(this).data('hash');
    const parent = $(this).data('parent');
    const containerId = $(this).data('container_id');
    const url = $(this).data('url') ? $(this).data('url') : ('/base/removeobject/' + objectName + '/' + id + '/' + hash + '/' + parent);
    const containerElem = $('#' + containerId);
    const containerElemWrap = $('#' + containerId + '-wrapper');
    const callback = $(this).data('callback');
    let additionalCallbacks = $(this).data('additionalcallbacks');
    const callback_args = $(this).data('callback-args') ? $(this).data('callback-args') : {};
    $('<p></p>').confirm(
      'Are you sure you want to delete this item?',
      function (event){
        if (event.response){
          // url = url + objectName + '/' + id + '/'+ hash + '/'  + parent;

          $.ajax({
            url: url,
            method: 'POST',
            beforeSend: function (){
              blockElement($('#' + containerId));
            },
            success: function (result){
              if (result.success){
                if (typeof containerId !== 'undefined'){
                  containerElem.remove();
                  containerElemWrap.remove();

                  if (undefined !== callback){
                    var func = window[callback];
                    result.json = result.json ? result.json : {};
                    func(Object.assign(result.json, callback_args));
                  }

                  if (undefined !== additionalCallbacks){
                    if (additionalCallbacks){
                      additionalCallbacks = additionalCallbacks.split(',');
                    } else {
                      additionalCallbacks = [];
                    }

                    for (let i = 0; i < additionalCallbacks.length; i++){
                      var func = window[additionalCallbacks[i]];
                      func();
                    }
                  }

                  refreshListWithPagination();
                }
              }
              FlashMessage.showFlashMessagesCallback();
              reinitTooltip();
              // adds border on conference view untill we find better css solution
              // TODO: remove planned conference border
              $('.profile-data--planned:last').addClass('profile-data--last-of-planned');
            },
            complete: function (){
              unblockElement($('#' + containerId));
            }
          });
        }
      },
      false,
      'Delete',
      'Cancel'
    );
  });

  $(document).on('click', '.js-remove-publication', function (){
    const url = $(this).data('url');

    $('<p></p>').confirm(
      'Are you sure you want to delete this item?',
      function (event){
        if (event.response){
          AjaxForms.deleteForm = new AjaxForm('#ajax_content', '#ajax_content', [FlashMessage.showFlashMessagesCallback]);
          AjaxForms.deleteForm.submit(url, 'POST');

          refreshListWithPagination();
        }
      },
      false,
      'Delete',
      'Cancel'
    );
  });

  // Foundation-datepicker - focus event works even on dynamic elements
  $(document).on('focus', '.fdatepicker', function (){
    let startDate = $(this).data('date-startdate');

    if (typeof startDate === 'undefined'){
      startDate = '01/01/1950';
    }

    $(this).fdatepicker({
      format: 'dd/mm/yyyy',
      startDate: startDate,
      leftArrow:'<<',
		  rightArrow:'>>'
    });
  });

  $(document).on('change', '.fdatepicker', function (){
    const id = $(this).attr('id');
    const dates_arr = { date_from: 'date_to' };

    const currentDate = $('#' + id).val();

    $.each(dates_arr, function (key, value){
      if (id.indexOf(key) >= 0){
        const end_id = id.replace(key, value);
        const endSelector = '#' + end_id;

        $(endSelector).fdatepicker({ format: 'dd/mm/yyyy', initialDate: currentDate });

        $(endSelector).fdatepicker('setStartDate', currentDate);
      } else if (id.indexOf(value) >= 0){
        const start_id = id.replace(value, key);
        const startSelector = '#' + start_id;

        $(startSelector).fdatepicker({ format: 'dd/mm/yyyy', initialDate: currentDate });

        $(startSelector).fdatepicker('setEndDate', currentDate);
      }
    });
  });

  $(document).on('focus', '.fdatepickerYM', function (){
    $(this).fdatepicker({
      format: 'mm/yyyy',
      startDate: '01/1950',
      startView: 3,
      minView: 'year',
      endDate: new Date()
    });
  });

  $(document).on('focus', '.fdatepickerYMD', function (){
    $(this).fdatepicker({
      format: 'yyyy-mm-dd',
      startDate: '01/01/1950'
    });
  });

  // get date in format dd/mm/yyyy
  function getDate(day){
    const date = new Date();
    date.setDate(date.getDate() + day);
    return date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
  }

  $(document).on('focus', '.fdatepicker-past', function (){
    $(this).fdatepicker({
      format: 'dd/mm/yyyy',
      startDate: '01/01/1950',
      endDate: getDate(-1)
    }).fdatepicker('update', getDate(-1));
  });

  $(document).on('focus', '.fdatepicker-feature', function (){
    $(this).fdatepicker({
      format: 'dd/mm/yyyy',
      startDate: getDate(0)
    });
  });

  $(document).on('focus', '.fdatepickerYear-past', function (){
    $(this).fdatepicker({
      format: 'yyyy',
      startView: 'decade',
      minView: 'decade',
      viewSelect: 'decade',
      autoclose: true,
      startDate: '1900',
      endDate: new Date()
    });
  });

  // user messages
  $(document).on('click', '.js-close-user-msg', function (){
    const _this = $(this);
    _this.parent().addClass('user-msg--close');
  });

  $(document).on('click', '.js-open-modal', function (){
    const url = $(this).data('url');

    ajaxPopupDialog(url);
  });

  $(document).on('click', '.js-open-modal-message', function (){
    const message = $(this).data('message');

    openFoundationMessage(message);
  });

  $(document).on('click', '.js-goto', function (e){
    e.preventDefault();
    const url = $(this).data('url');
    const blank_page = $(this).data('blank');
    if (blank_page){
      window.open(url,'_blank');
    } else {
      window.location = url;
    }
  });

  function publicationUploadStatus() {
    return {
      fileStatus: false,
      checkboxStatus: false
    }
  }
  const publicationUploadStatusResult = publicationUploadStatus();
  let file = publicationUploadStatusResult.fileStatus;
  let check = publicationUploadStatusResult.checkboxStatus;

  const publicationBtnStatus = () => {
    let fileRequired = $("#publication-disclaimer").data('file-required');
    if((fileRequired ? (file == true) : true) && check == true) {
      $('.js-ajax-submit, .js-ajax-popup-form-submit').addClass('btn--primary').removeClass('btn--disabled');
    } else {
      $('.js-ajax-submit, .js-ajax-popup-form-submit').addClass('btn--disabled');
    }
  }

  $(document).on('change', '.js-file-input', function() {
    const nameContainer = '.js-file-name-wrap';
    if (this.files.length == 0){
      $(nameContainer).hide();
      file = false;
      publicationBtnStatus();
    } else {
      if($('.publication-disclaimer').hasClass('hide')) $('.publication-disclaimer').removeClass('hide')
      $(nameContainer).show();
      const show = $(this).attr('data-show-file');
      $(show).text(this.files[0].name);
      file = true;
      publicationBtnStatus();
    }
  });

    // Disable search button in no PIMID or DOI entered on add new publication page
  $(document).ajaxComplete(function() {
    let searchArticle = $('.js-search-article');
    if(searchArticle.length > 0 && searchArticle.val() !== '') {
      $('.js-search-publication-btn').prop('disabled', false);
    }


    $(document).on('input', '.js-search-article', function() {
      if($(this).val() == '') {
        $('.js-search-publication-btn').prop('disabled', true);
      } else {
        $('.js-search-publication-btn').prop('disabled', false);
      }
    })
  })

  // Attaching the click event on the button
  $(document).on("change", "#publication-disclaimer", function() {
    check = $(this).is(":checked");
    publicationBtnStatus();
  });

  // Click event on removing previously uploaded file and if user closes popup reset all
  $(document).on('click', '.js-sffile-upload--remove, .js-close-button', function() {
    const disclaimerContainer = $('.disclaimer-container');
    const fileVisibleContainer = $('.file-visible-container');
    check = false;
    file = false;
    publicationBtnStatus();
    disclaimerContainer.addClass('is-hidden');
    fileVisibleContainer.addClass('is-hidden');
    $('.js-ajax-popup-form-submit').removeClass('btn--disabled');
    disclaimerContainer.find('#publication-disclaimer').prop('checked', false);
  });

  $(document).on('change', '.js-spfile-input', function (){
    const filesContainer = $(this).next('.js-sffile-upload--files');
    const filetemplate = $(this).nextAll('.js-spfile-prototype').first();
    const disclaimerContainer = $('.disclaimer-container');
    const fileVisibleContainer = $('.file-visible-container');

    let prototypeHtml = filetemplate.html();
    const fileName = this.files[0].name;
    prototypeHtml = prototypeHtml.replace('__file__name', fileName);
    $(filesContainer).empty();
    $(filesContainer).append($(prototypeHtml));

    if (this.files.length == 0){
      file = false;
      publicationBtnStatus();
    } else {
      disclaimerContainer.removeClass('is-hidden');
      fileVisibleContainer.removeClass('is-hidden');
      file = true;
      publicationBtnStatus();
    }
  });

  // reset checkbox if user check it under bib upload and after open article by DOI,
  // to prevent to be checked when popup is open
  $(document).on('click', '.js-confirm-btn', function() {
    $('#publication-disclaimer').prop('checked', false);
    check = false;
  });

  $(document).on('click', '.js-sffile-upload--remove', function (e){
    e.preventDefault();
    const inputElementId = $(this).attr('data-name-attr');
    const container = $(this).closest('.js-sffile-upload--files');

    container.empty();
    $('#' + inputElementId).val(null);
  });

  if ($(document).find('.js-tab')){
    $('.js-tab:first').trigger('click');
  }

  //if maintenance mode turn on adjust body wrapper class for mobile or other resolutions where text is im more then one line.
  if ($('.body__wrapper').length > 0 && $('.maintanance_mode ').is(':visible')) {
    let bodyWrapperHeight = $('.header').height();
    let nonLoggedMessage = $('.non-logged').height();
    if (typeof bodyWrapperHeight === 'undefined') {
      bodyWrapperHeight = $('.non-logged').height();
    } else {
      bodyWrapperHeight += nonLoggedMessage;
    }

    $('.body__wrapper').css('margin-top', bodyWrapperHeight);
  }
});

$(document).ready(function() {
  const toBottom = $('.js-scroll-to-bottom-warpper');
  const windowHeight = $(window).height();

  function bottomButtonDisplay() {
    const getVerticalPosition = $(window).scrollTop();
    const documentHeight = $(document).height();
    getVerticalPosition + windowHeight + 700 > documentHeight ? toBottom.removeClass('scroll-to-bottom--show') : toBottom.addClass('scroll-to-bottom--show');
  }

  bottomButtonDisplay();

  var observer = new MutationObserver(function(mutations) {
    mutations.forEach(function(mutation) {
      if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
        bottomButtonDisplay();
      }
    });
  });

  var config = { childList: true, subtree: true };
  const targetNode = $('.js-scroll-container').get(0);
  if (targetNode) {
    observer.observe(targetNode, config);
  }
});

$(window).on('scroll', function () {
  const getVerticalPosition = $(window).scrollTop();
  const windowHeight = $(window).height();
  const documentHeight = $(document).height();
  const toTop = $('.js-scroll-to-top-warpper');
  const toBottom = $('.js-scroll-to-bottom-warpper');

  getVerticalPosition > 700 ? toTop.addClass('scroll-to-top--show') : toTop.removeClass('scroll-to-top--show');
  getVerticalPosition + windowHeight + 700 > documentHeight ? toBottom.removeClass('scroll-to-bottom--show') : toBottom.addClass('scroll-to-bottom--show');
});

// Collapse content
$(document).ready(function (){
  $('.js-collapse-trigger').click(function (){
    $(this).toggleClass('js-collapse-expanded');
    $(this).next().toggleClass('js-collapse');
  });
});

// ajax error
$(document).ajaxError(function (event, jqxhr, settings, thrownError){
  if (jqxhr.status == 403){
    location.href = jqxhr.responseText;
  }
  const response = jqxhr.responseJSON;
  if (typeof response !== 'undefined'){
    if (response.flashMessage){
      if (response.flashMessage.content){
        FlashMessage.showShortMessage(response.flashMessage);
      } else {
        FlashMessage.showErrorMessage('An error has occurred , Please contact our office!');
      }
    } else if (response.message){
      FlashMessage.displayFlashMessages(response.message);
    }
  }
});

function _sfa(jsonData, type){
  const url = '/elastic/hit';
  const body = jQuery.parseJSON(jsonData);
  const options = { fonts: { extendedJsFonts: true }, excludes: { userAgent: true } };
  Fingerprint2.get(options, function (components){
    body.action_type = type;
    const values = components.map(function (component){ return component.value; });
    const murmur = Fingerprint2.x64hash128(values.join(''), 31);
    body.fingerprint = murmur;
    const data = {
      body: body
    };

    $.ajax({
      type: 'POST',
      url: url,
      data: { data: data },
      success: function (data, textStatus, jqXHR){

      }
    });
  });
}

$(document).on('input focus', '.js-input-counter', function (){
  fillTaCounter(this);
});

$(document).on('click', '.js-articleRead', function (){
  const object = JSON.parse($(this).data('object'));
  _sfa(object, $(this).data('type'));
});
$(document).on('click', '.js-ajax-paginator ', function (e){
  e.preventDefault();
  const url = $(this).attr('href');
  const container_id = $(this).data('container');
  let method = $(this).data('method');
  if (!method){
    method = 'POST';
  }
  const update_elem = $('#' + container_id);
  blockElement(update_elem);
  $.ajax({
    type: method,
    url: url,
    contentType: false, // The content type used when sending data to the server.
    cache: false, // To unable request pages to be cached
    processData: false,
    success: function (result){
      if (update_elem.length > 0){
        update_elem.html(result.responseView);
        reinitTooltip();
        $(document).foundation();
        scrollToElement(update_elem);
      }
    },
    complete: function (){
      unblockElement(update_elem);
    }
  });
});

function customAjaxCall(object){
  object.prop('disabled', true);
  const url = object.data('url');
  const javascriptCallback = object.data('callback');
  const container_id = object.data('container');
  let flashMessage = null;
  let update_elem = null;

  $.ajax({
    type: 'POST',
    url: url,
    contentType: false, // The content type used when sending data to the server.
    cache: false, // To unable request pages to be cached
    processData: false,
    success: function (data){
      if (data.hasOwnProperty('goToUrl') && data.goToUrl){
        window.location.href = data.goToUrl;
      }

      if (data.success){
        const element = $(data.responseView);

        if (data.hasOwnProperty('reloadPage') && data.reloadPage){
          location.reload();
        }
        if (container_id){
          update_elem = $('#' + container_id);
        }

        if (data.hasOwnProperty('removeOnStart') && data.removeOnStart && update_elem){
          update_elem.remove();
        }

        if (undefined !== javascriptCallback){
          const func = window[javascriptCallback];
          func(object, data);
        }
        if (update_elem && data.hasOwnProperty('disable_container') && data.disable_container){
          update_elem.addClass('deleted_row');
          $('.' + container_id).addClass('deleted_row'); // for additional row - container name as class
        } else if (update_elem && update_elem.length > 0){
          if (data.hasOwnProperty('remove') && data.remove){
            update_elem.remove();
          } else {
            update_elem.replaceWith(element);
            $('.js-ajaxLoadBox').each(function (i, obj){
              fillInvisibleContent($(this));
            });
          }
        }
      }

      if (object.hasClass('has-tip')){
        // remove open tooltip
        reinitTooltip();
      }

      if (data.hasOwnProperty('flashMessage') && data.flashMessage){
        flashMessage = data.flashMessage;
      }
    },
    complete: function (){
      if (flashMessage){
        FlashMessage.showShortMessage(flashMessage);
      }

      object.prop('disabled', false);
    }
  });
}

// textarea counter
function fillTaCounter(ta){
  const textArea = $(ta);
  const elementName = $(ta).attr('name');
  const value = textArea.val();
  const max = textArea.attr('maxlength');

  // from TinyMCE word count plugin for emoji characters
  let totalChars = value.replace(/[\uD800-\uDBFF][\uDC00-\uDFFF]/g, '_').length;

  if (max){
    totalChars = totalChars + '/' + max;
  }

  // var counter = $(".js-ta-counter");
  const counter = $(".js-ta-counter[data-for='" + elementName + "']");

  if (counter.length == 0){
    textArea.after("<p class='help-text js-ta-counter' data-for ='" + elementName + "'>" + totalChars + ' characters</p>');
  } else {
    counter.html(totalChars + ' characters');
  }
}

function reinitTooltip(){
  $('.tooltip').remove();
  $('.has-tip').foundation();
  Foundation.reInit($('.has-tip'));
}

function capitalizeFirstLetter(string){
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function redirectToRoute(data){
  window.location.href = data.route;
}

function getElement(selector){
  if ($('.' + selector).length){
    return $('.' + selector);
  }
  if ($('#' + selector).length){
    return $('#' + selector);
  }

  return null;
}

$('.need-confirm').on('click', function (e){
  let msg = 'Are you sure for this operation ?';
  if ($(this).data('message')){
    msg = $(this).data('message');
  }
  if (!confirm(msg)){
    e.preventDefault();
    e.stopImmediatePropagation();
    return false;
  }
});

function newSearch(){
  const search = $('.new-search');
  let url = search.data('url');
  const container = search.data('container');
  const keywords = search.find("input[name='keywords']").val();
  if (url.indexOf('?') === -1){
    url += '?';
  } else {
    url += '&';
  }
  url += 'keywords=' + keywords;

  $.ajax({
    url: url,
    dataType: 'json',
    cache: false,
    processData: false,
    beforeSend: function (){
      blockElement($(container));
    },
    success: function (result){
      if (result.responseView){
        $(container).html(result.responseView);
      }
    },
    complete: function (){
      unblockElement($(container));
    }
  });
}

$(document).on('keyup', '.new-search input', function (e){
  if (e.keyCode === 13){
    newSearch();
  }
});

$(document).on('click', '.new-search .float-right', function (){
  newSearch();
});

$(document).on('input', '.new-search input[name=keywords]', function (){
  if ($(this).val() === ''){
    $('.new-search .close-button').hide();
  } else {
    $('.new-search .close-button').show();
  }
});

$(document).on('click', '.new-search .close-button', function (){
  $('.new-search input[name=keywords]').val('');
  $(this).hide();
  newSearch();
});

function setFileIcon(){
  $('.js-filename-icon-image').each(function (){
    const img = '<svg height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M21.75 3.75H2.25C1.83516 3.75 1.5 4.08516 1.5 4.5V19.5C1.5 19.9148 1.83516 20.25 2.25 20.25H21.75C22.1648 20.25 22.5 19.9148 22.5 19.5V4.5C22.5 4.08516 22.1648 3.75 21.75 3.75ZM20.8125 18.5625H3.1875V17.6273L6.43359 13.7766L9.95156 17.9484L15.4242 11.4609L20.8125 17.85V18.5625ZM20.8125 15.5203L15.5672 9.3C15.4922 9.21094 15.3562 9.21094 15.2812 9.3L9.95156 15.6188L6.57656 11.618C6.50156 11.5289 6.36562 11.5289 6.29062 11.618L3.1875 15.2977V5.4375H20.8125V15.5203ZM7.125 10.6875C7.39585 10.6875 7.66405 10.6342 7.91428 10.5305C8.16452 10.4269 8.39189 10.2749 8.58341 10.0834C8.77493 9.89189 8.92685 9.66452 9.0305 9.41428C9.13415 9.16405 9.1875 8.89585 9.1875 8.625C9.1875 8.35415 9.13415 8.08595 9.0305 7.83572C8.92685 7.58548 8.77493 7.35811 8.58341 7.16659C8.39189 6.97507 8.16452 6.82315 7.91428 6.7195C7.66405 6.61585 7.39585 6.5625 7.125 6.5625C6.57799 6.5625 6.05339 6.7798 5.66659 7.16659C5.2798 7.55339 5.0625 8.07799 5.0625 8.625C5.0625 9.17201 5.2798 9.69661 5.66659 10.0834C6.05339 10.4702 6.57799 10.6875 7.125 10.6875ZM7.125 7.96875C7.48828 7.96875 7.78125 8.26172 7.78125 8.625C7.78125 8.98828 7.48828 9.28125 7.125 9.28125C6.76172 9.28125 6.46875 8.98828 6.46875 8.625C6.46875 8.26172 6.76172 7.96875 7.125 7.96875Z" /></svg>';
    $(this).prepend(img);
    $(this).removeClass('js-filename-icon-image');
  });
  $('.js-filename-icon-video').each(function (){
    const img = '<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M21.375 7.08516L18.375 8.8125V5.25C18.375 4.42266 17.7023 3.75 16.875 3.75H3C2.17266 3.75 1.5 4.42266 1.5 5.25V18.75C1.5 19.5773 2.17266 20.25 3 20.25H16.875C17.7023 20.25 18.375 19.5773 18.375 18.75V15.1875L21.375 16.9148C21.8742 17.2031 22.5 16.8422 22.5 16.268V7.73438C22.5 7.15781 21.8742 6.79687 21.375 7.08516ZM16.6875 18.5625H3.1875V5.4375H16.6875V18.5625ZM20.8125 14.6484L18.375 13.2469V10.7555L20.8125 9.35156V14.6484ZM4.875 8.4375H7.5C7.60313 8.4375 7.6875 8.35313 7.6875 8.25V7.125C7.6875 7.02187 7.60313 6.9375 7.5 6.9375H4.875C4.77187 6.9375 4.6875 7.02187 4.6875 7.125V8.25C4.6875 8.35313 4.77187 8.4375 4.875 8.4375Z" /></svg>';
    $(this).prepend(img);
    $(this).removeClass('js-filename-icon-video');
  });
  $('.js-filename-icon-audio').each(function (){
    const img = '<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M14.6695 2.69531C14.5313 2.69531 14.3906 2.73281 14.2617 2.81953L5.95312 8.25H2.10938C1.90312 8.25 1.73438 8.41875 1.73438 8.625V15.375C1.73438 15.5813 1.90312 15.75 2.10938 15.75H5.95312L14.2617 21.1805C14.3906 21.2648 14.5336 21.3047 14.6695 21.3047C15.0609 21.3047 15.4219 20.993 15.4219 20.5523V3.44766C15.4219 3.00703 15.0609 2.69531 14.6695 2.69531ZM13.7344 18.8203L6.87656 14.3367L6.45469 14.0625H3.42188V9.9375H6.45469L6.87422 9.66328L13.7344 5.17969V18.8203ZM21.8906 11.1562H18.8906C18.6844 11.1562 18.5156 11.325 18.5156 11.5312V12.4688C18.5156 12.675 18.6844 12.8438 18.8906 12.8438H21.8906C22.0969 12.8438 22.2656 12.675 22.2656 12.4688V11.5312C22.2656 11.325 22.0969 11.1562 21.8906 11.1562ZM20.9086 17.2922L18.3234 15.7992C18.281 15.7749 18.2341 15.7592 18.1856 15.753C18.137 15.7468 18.0877 15.7502 18.0405 15.763C17.9933 15.7759 17.949 15.7979 17.9103 15.8278C17.8716 15.8578 17.8392 15.895 17.8148 15.9375L17.3484 16.7461C17.2453 16.9242 17.3062 17.1539 17.4844 17.257L20.0695 18.75C20.112 18.7743 20.1589 18.79 20.2074 18.7962C20.2559 18.8025 20.3052 18.799 20.3525 18.7862C20.3997 18.7734 20.4439 18.7513 20.4827 18.7214C20.5214 18.6915 20.5538 18.6542 20.5781 18.6117L21.0445 17.8031C21.1477 17.625 21.0844 17.3953 20.9086 17.2922ZM17.8125 8.0625C17.8368 8.10498 17.8693 8.14225 17.908 8.17219C17.9467 8.20213 17.9909 8.22414 18.0382 8.23699C18.0854 8.24983 18.1347 8.25324 18.1832 8.24703C18.2318 8.24081 18.2786 8.2251 18.3211 8.20078L20.9062 6.70781C21.0844 6.60469 21.1453 6.375 21.0422 6.19687L20.5781 5.39062C20.5538 5.34815 20.5214 5.31088 20.4827 5.28094C20.4439 5.251 20.3997 5.22898 20.3525 5.21614C20.3052 5.2033 20.2559 5.19989 20.2074 5.2061C20.1589 5.21231 20.112 5.22802 20.0695 5.25234L17.4844 6.74531C17.3988 6.79521 17.3364 6.87698 17.3109 6.97272C17.2855 7.06847 17.299 7.17041 17.3484 7.25625L17.8125 8.0625Z" /></svg>';
    $(this).prepend(img);
    $(this).removeClass('js-filename-icon-audio');
  });
  $('.js-filename-icon-text').each(function (){
    const img = '<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M20.0297 6.76406L14.9859 1.72031C14.8453 1.57969 14.6555 1.5 14.4562 1.5H4.5C4.08516 1.5 3.75 1.83516 3.75 2.25V21.75C3.75 22.1648 4.08516 22.5 4.5 22.5H19.5C19.9148 22.5 20.25 22.1648 20.25 21.75V7.29609C20.25 7.09687 20.1703 6.90469 20.0297 6.76406ZM18.5203 7.64062H14.1094V3.22969L18.5203 7.64062ZM18.5625 20.8125H5.4375V3.1875H12.5156V8.25C12.5156 8.51107 12.6193 8.76145 12.8039 8.94606C12.9885 9.13066 13.2389 9.23438 13.5 9.23438H18.5625V20.8125Z" /></svg>';
    $(this).prepend(img);
    $(this).removeClass('js-filename-icon-text');
  });
}

function getParameter(parameter, defValue){
  if (typeof parameter !== 'undefined' && parameter){
    return parameter;
  }

  if (typeof defValue !== 'undefined' && defValue){
    return defValue;
  }

  return null;
}

function getDataValue(object, dataName){
  return object.attr('data-' + dataName);
}

function findDuplicatedFormIds(elementId){
  const idsCount = { elementId: 0 };
  $('form').each(function (){
    const formId = $(this).attr('id');
    if (formId == elementId){
      idsCount.elementId = idsCount.elementId + 1;
    }
    if (idsCount.elementId > 1){
      return false;
    }
  });
  return idsCount.elementId > 1;
}

function newGuid(){
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g,
    function (c){
      const r = Math.random() * 16 | 0;
      const v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    }).toUpperCase();
}

$(document).ajaxComplete(function (){
  if (window.location.hash){
    const hash = window.location.hash;
    const elementId = hash.substring(1);
    const object = document.getElementById(elementId);
    if (object && !$(object).hasClass('js-no-ajax-complete-scroll')){
      object.scrollIntoView();
    }
  }
});

function sortable(selector){
  if (undefined === selector){
    selector = '#sortable';
  }
  const url = $(selector).data('url');
  $(selector).sortable({
    update: function (event, ui){
      const targetObj = $(event.target);
      const ids = [];
      targetObj.children().each(function (i, e){
        ids.push($(e).data('id'));
      });
      if (ids.length > 0){
        $.ajax({
          url: url,
          method: 'POST',
          data: { ids: ids },
          beforeSend: function (){
            targetObj.sortable('disable');
            targetObj.block();
          },
          complete: function (){
            targetObj.sortable('enable');
            targetObj.unblock();
          }
        });
      }
    }
  });
}

function jobRecommendCallback(data){
  $(document).find('.js-job-recommend-' + data.job_id).replaceWith(data.responseView);
}

function jobSaveCallback(data){
  $(document).find('.js-job-save-' + data.job_id).replaceWith(data.responseView);
}

// basic info icon aligment
// TODO: move this to separate file
$(document).ready(function() {
  let profileElm = $('.profile-info__link');
  profileElm.each(function(i, obj) {
    let objectHeight = $(obj).height();
    if(objectHeight < 42) {
      $(this).addClass('align-center');
    }
  })
})

function addKeywordComponent()
{
  let addKeywordBtn = $('.js-tag-keyword-add')

  addKeywordBtn.each(function () {
    let addBtn = $(this);
    let keywordInputForm = addBtn.parent().find('.js-keyword-input-form')

    keywordInputForm.hide()
    addBtn.on('click', (e) => {
      e.preventDefault()
      addBtn.hide()
      keywordInputForm.show()
      keywordInputForm.find('input').focus()
    })

    keywordInputForm.focusout(function () {
      keywordInputForm.hide()
      addBtn.show()
    })
  })
}

$(document).ready(function() {
  addKeywordComponent()
});

// Alert close
$(document).ready(function() {
  let closeAlert = $('.js-alert-close');
  let alertBox = $('.sp-alert');
  closeAlert.on('click', alertBox, function() {
    $(this).closest(alertBox).addClass('sp-alert--hidden');
  })
});

// copy content of element to clipboard
function copyToClipboard(elContentToCopy) {
  let sampleTextarea = document.createElement("textarea");
  document.body.appendChild(sampleTextarea);

  if (elContentToCopy.data('share-link')) {
    sampleTextarea.value = elContentToCopy.data('share-link');
  } else {
    sampleTextarea.value = elContentToCopy.text() ? elContentToCopy.text() : elContentToCopy.val();
  }

  sampleTextarea.select();
  document.execCommand("copy");
  document.body.removeChild(sampleTextarea);
}

$(document).on('click', '.js-copy-to-clipboard', function() {
  let flashMsg = $(this).parent('.js-set-flash-message');
  let flashMsgContent = '';

  if (typeof flashMsg.data('flash-message') !== 'undefined') {
    flashMsgContent = flashMsg.data('flash-message');
  } else {
    flashMsgContent = 'Copied!';
  }

  try {
    copyToClipboard($(this));
    FlashMessage.showShortMessage({
      content: flashMsgContent,
      type: "success"
    });
  } catch(error) {
    FlashMessage.showShortMessage({
      content: 'Something went wrong!' + error,
      type: "error"
    });
  }
});

//$(window).on('scroll', function(){
//
//  let winSize = $(window).height();
//  let jsSticky = $('.js-sticky').height();
//  let bottomOffset = $('body').css('font-size');
//  let top_of_element = $(".js-sticky").offset().top;
//  let bottom_of_element = $(".js-sticky").offset().top + $(".js-sticky").outerHeight();
//  let bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
//  let top_of_screen = $(window).scrollTop();
//
//  if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element-winSize)){
//      // the element is visible
//
//    } else {
//      // the element is not visible
//     $('.js-sticky').attr({
//        'data-margin-top': '-'+(jsSticky-winSize)/16-1,
//        'data-sticky':'',
//        'data-dynamic-height':'true'
//      });
//      $('.js-sticky').css({'margin-top': '-'+(jsSticky-winSize)/16-1 +'em'})
//      $('.js-sticky').closest('.cell').attr('data-sticky-container','');
//      $(document).foundation();
//      $('.sticky').foundation('_calc', true);
//  }
//})

function openPostDetails(e) {
  if(e.target.nodeName == 'A') {
    return;
  } else {
    window.open(Routing.generate('single_post', { id: $(e.target).closest('.js-post-details').data('id') }), '_blank');
  }
  e.preventDefault();
}

$(document).on('click', '.js-post-details', openPostDetails);

function formWithSteps() {
  // Form with steps functionality
  // Description: Minimum 2 steps per form. Only one form per page.
  //              The initial form this feature is created for is shown in modal.
  //              Initial state should be already setup before this functionality is applied,
  //              by adding 'is-active' class for the related first step and its content
  // Class js-form-step: used for step title, uses parameter 'data-step' for the step number
  // Class js-form-step-content: used for all elements that should appear when its step is active
  //                             uses parameter 'data-step_content' for the content of the step number
  // Class js-form-step-next: button that is used to go to next step
  // Class js-form-step-previous: button that is usd to go on previous step

  // 1. Constants and partial initialisation
  const totalSteps = $('.js-form-step').length;
  const btnNext = $('.js-claim-profile-next');
  const btnSubmit = $("button.js-form-step-submit");
  const btnPrevious = $('.js-form-steps-previous');
  btnSubmit.hide();

  $('.js-form-step.form-step .tabs-title__content').on('click', function (e) {
    e.preventDefault();
  });

  // 2. Functionality (Next)
  btnNext.on('click', function () {
    let activeStepTitle = $('.js-form-step.is-active');
    let activeStepContent = $('.js-form-step-content.is-active');
    let activeStep = activeStepTitle.data('step');
    let nextStep = activeStep + 1;
    let nextStepTitle = $('.js-form-step[data-step="'+nextStep+'"]');
    let nextStepContent = $('.js-form-step-content[data-step="'+nextStep+'"]');
    let callback = $('.js-form-with-steps').data('callback');
    let validator = $('.js-form-with-steps').data('validator');

    if (validator) {
      if (!window[validator]()) {
        return;
      }
    }

    activeStepTitle.removeClass('is-active');
    nextStepTitle.addClass('is-active');
    activeStepContent.removeClass('is-active');
    nextStepContent.addClass('is-active');

    if (nextStep === totalSteps) {
      btnNext.hide();
      btnSubmit.show()
      btnPrevious.show();
    } else {
      btnNext.show();
      btnSubmit.hide();
      if (nextStep !== 1) {
        btnPrevious.show();
      } else {
        btnPrevious.hide();
      }
    }
    if (callback) {
      window[callback]();
    }
  });

  // 3. Functionality (Previous)
  btnPrevious.on('click', function () {
    let activeStepTitle = $('.js-form-step.is-active');
    let activeStepContent = $('.js-form-step-content.is-active');
    let activeStep = activeStepTitle.data('step');
    let previousStep = activeStep - 1;
    let previousStepTitle = $('.js-form-step[data-step="'+previousStep+'"]');
    let previousStepContent = $('.js-form-step-content[data-step="'+previousStep+'"]');
    activeStepTitle.removeClass('is-active');
    previousStepTitle.addClass('is-active');
    activeStepContent.removeClass('is-active');
    previousStepContent.addClass('is-active');

    if (previousStep === 1) {
      btnNext.show();
      btnSubmit.hide()
      btnPrevious.hide();
    } else {
      btnPrevious.show();
      if (previousStep !== totalSteps) {
        btnNext.show();
        btnSubmit.hide();
      } else {
        btnNext.hide();
        btnSubmit.show();
      }
    }
  });
}

function openMessageDialog(url) {
  let msgDialog = $('.js-dialog');

  $.ajax({
    url: url,
    method: 'GET',
    success: function (res) {
      msgDialog.html(res.responseView);
      msgDialog.attr('data-topic', res.topic);
      if (msgDialog.hasClass('message-dialog--close')) {
        msgDialog.removeClass('message-dialog--close');
      }
      reloadTotalMessages();
    }
  })
}

// Refresh rate for new message check

let timeout = 5000;
let timeoutStepUp = 5000;
let currentTimeout = timeout;
let maxTimeout = 60000;
let intervalId;

function reloadTotalMessages() {
	let messageCountWrapper = $('#unread-message-count-wrapper');

	$.get(messageCountWrapper.data('url'), function (response) {
		messageCountWrapper.html(response.responseView);

		changeTimer(response);

		clearInterval(intervalId);

		intervalId = setInterval(reloadTotalMessages, currentTimeout);
	});
}

function changeTimer(res) {
	if (res.responseView && res.responseView.length > 0) {
		//currentTimeout = Math.max(currentTimeout - 500, 500);
		currentTimeout = timeout;
	} else {
		currentTimeout = Math.min(currentTimeout + timeoutStepUp, maxTimeout);
	}
}

function scrollX(scrollWrapper, direction) {
  let scrollWrapperOffsetWidth = scrollWrapper[0].offsetWidth;
  let scrollWrapperCurrentPosition = scrollWrapper.scrollLeft();
  let scrollValue;

  if(direction == 'left') {
    scrollValue = scrollWrapperCurrentPosition - scrollWrapperOffsetWidth;
  } else {
    scrollValue = scrollWrapperCurrentPosition + scrollWrapperOffsetWidth;
  }

  scrollWrapper.animate({
    scrollLeft: scrollValue
  });
}

$(document).on('click', '.js-scroll-left', function() {
  let scrollWrapper = $(this).parent().siblings('.advisory__card-wrapper');
  scrollX(scrollWrapper, 'left');
});

$(document).on('click', '.js-scroll-right', function() {
  let scrollWrapper = $(this).parent().siblings('.advisory__card-wrapper');
  scrollX(scrollWrapper);
});

$(document).ready(function () {
  const shareUrl = $('.js-qrcode').data('qr-code');

  $(".js-qrcode").qrcode({
    text: shareUrl
  });
});

$(document).on('click', '.js-testimonial-slide', function() {
  let slideDirection = $(this).data('direction');
  let slideContainer = $(this).data('slide-container');
  scrollX($(`.${slideContainer}`), slideDirection);
});

function blogActiveCategory(activeContainer) {
  $(`#${activeContainer}`).addClass('is-active');
}

$(document).ready(function () {
  const resendButton = $('.js-resend-button');
  const time = 60000;
  var lastClickTime = localStorage.getItem('lastClickTime');

  if (lastClickTime && (new Date().getTime() - lastClickTime < time)) {
    const remainTime = time - (new Date().getTime() - lastClickTime);
    if (remainTime > 0) {
      startCountdown(remainTime);
    }
  }

  resendButton.on('click', function (e) {
    localStorage.setItem('lastClickTime', new Date().getTime());
  });
})
